<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <logo />
        <h2 class="brand-text text-primary ml-1">
          Care Programs
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->
      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div
            v-if="showRedirect === false"
            class="d-flex justify-content-center mb-1"
          >
            <strong>Please wait... We are authencticating this request...
            </strong>
            <b-spinner label="Loading..." />
          </div>
          <b-button
            v-if="showRedirect === true"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="mt-2 mr-1"
            @click="redirectPharmacist()"
          >
            <feather-icon icon="CheckIcon" />
            <strong> Authentication successful.</strong> Please click here to visit patient data.
          </b-button>

          <div
            v-if="showRedirect === false"
            class="d-flex justify-content-center mb-1"
          >
            <strong>Authentication has failed. Please try to reload or restart the process.
            </strong>
          </div>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import Logo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BImg, BSpinner, VBTooltip, BLink, BButton,
} from 'bootstrap-vue'
import { useBackendAuth } from '@/auth/backend/userBackend'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { isUserLoggedIn } from '@/auth/utils'
import router from '@/router'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { initialAbility } from '@/libs/acl/config'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BSpinner,
    Logo,
    BRow,
    BCol,
    BImg,
    BLink,
    BButton,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2-dark.svg'),
      isAuthenticated: false,
      showRedirect: false,

      // validation rules
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    this.checkAuth()
  },
  methods: {
    checkAuth() {
      const isLoggedIn = isUserLoggedIn()
      if (!isLoggedIn) {
        store
          .dispatch('userStore/checkPharmacyAuth', {
            userId: router.currentRoute.params.id,
            pharmacyId: router.currentRoute.params.pid,
          })
          .then(response => {
          // if is logged in successful then redirect
            const { status } = response
            if (status === 200) {
              this.showRedirect = true
              const userData = response.data.data
              useBackendAuth.setToken(userData.accessToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(userData.ability)
            } else {
              this.showRedirect = false
              this.$bvToast.toast(
                `ERROR:${response.message}`,
                {
                  title: 'Error',
                  variant: 'danger',
                  solid: true,
                },
              )
            }
          })
          .catch(() => {
            this.showRedirect = false
            this.$bvToast.toast(
              'We could not process your action this time. please try again.',
              {
                title: 'Error',
                variant: 'danger',
                solid: true,
              },
            )
          })
      } else {
        this.showRedirect = true
        // this.logout()
      }
    },
    redirectPharmacist() {
      const route = this.$router.resolve({
        name: 'pharmacy-patient-visit-authenticated',
        params: { id: router.currentRoute.params.id, pid: router.currentRoute.params.pid },
      })
      window.open(route.href, '_self')
    },
    logout() {
      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to auto auth page
      // this.$router.push({ name: 'pharmacy-patient-add-visit-data' })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
